import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import style from "../scss/components/BlogRoll.module.scss";
import BlogCard from "../components/BlogCard";

class TagEachPageRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    console.log(posts);
    const postLinks = posts.map((post) => (
      <div className={style.blog_list__wrapper}>
        <div className={style.blog_list}>
          {posts &&
            posts.map(({ node: post }, i) => <BlogCard key={i} post={post} />)}
        </div>
      </div>
    ));
    const tag = this.props.pageContext.tag;

    return (
      <Layout>
        <div className="contents-margin">
          <div className={style.tag_title}>{tag}</div>
          <div className={style.blog_list__wrapper}>
            <div className={style.blog_list}>
              {posts &&
                posts.map(({ node: post }, i) => (
                  <BlogCard key={i} post={post} />
                ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TagEachPageRoute;

export const tagEachPageQuery = graphql`
  query TagEachPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "YYYY / MM / DD")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
